* {
  font-family: "Roboto Condensed", sans-serif !important;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
  font-size: 85%;
}
::selection {
  background: #cea913;
}
@media screen and (min-width: 576px) {
  html,
  body,
  #root {
    font-size: 90% !important;
  }
}
@media screen and (min-width: 768px) {
  html,
  body,
  #root {
    font-size: 95% !important;
  }
}
@media screen and (min-width: 992px) {
  html,
  body,
  #root {
    font-size: 100% !important;
  }
}
body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
.hero-button {
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  font-size: 0.9375rem;
  padding: 18px 40px;
  border: none;
  border-radius: 8px;
}
.hero-button:hover {
  cursor: pointer;
}
img {
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.app {
  overflow-x: hidden;
}
main {
  position: relative;
}
.logo-bck-0 {
  background-image: linear-gradient(120deg, #c29403, #e1bb25);
}
.logo-bck-1 {
  background-image: linear-gradient(40deg, #98bf0d, #83b007 50%, #72a503);
}
.logo-bck-2 {
  background-image: linear-gradient(40deg, #a70707, #ff0100 50%, #ff2f2f);
}
.cancel-anim {
  animation: none !important;
  opacity: 1 !important;
}
.primary-btn {
  position: relative;
  line-height: 100%;
  transition: transform 0.22s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.primary-btn:hover,
.primary-btn:focus,
.primary-btn:active {
  transform: scale(1.18);
}
.primary-btn:hover button:before,
.primary-btn:focus button:before,
.primary-btn:active button:before {
  opacity: 1;
}
.primary-btn p {
  position: absolute;
  z-index: 101;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 16px;
  cursor: pointer;
  margin-top: 1px;
}
.primary-btn button {
  padding: 0 40px;
  height: 50px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  background-image: linear-gradient(50deg, hsl(45, 100%, 34%) 0%, hsl(47, 91%, 39%) 50%, hsl(48, 83%, 44%) 100%);
  background-size: 100%;
  position: relative;
  z-index: 100;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}
.primary-btn button:before {
  background-image: linear-gradient(50deg, hsl(48, 83%, 44%) 0%, hsl(47, 91%, 39%) 50%, hsl(45, 100%, 34%) 100%);
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  z-index: -100;
  transition: opacity 0.6s;
  border-radius: 8px;
}
.primary-btn button:hover:before {
  opacity: 1;
}
.primary-btn button p {
  opacity: 0;
  position: relative;
}
.links-icon {
  display: flex;
  align-items: center;
  gap: 10px;
}
.links-icon a {
  display: inline-block;
  line-height: 100%;
  transition: transform 0.3s ease-out;
  color: #000;
}
.links-icon a:hover {
  transform: scale(1.3);
}
.links-icon .facebook {
  margin-right: -5px;
}
.links-icon .location {
  margin-left: -2px;
}
.links-icon img {
  width: 7px;
  height: 7px;
  margin-top: -1px;
}
.links-icon svg {
  width: 15px;
  height: auto;
  fill: #000;
}
.loader {
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 10000;
  background-color: #1b1b1b;
  transition: transform 1.4s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loader-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  gap: 50px;
}
.loader-icon {
  transition: all 0.8s ease-out;
  transform: scale(1);
  opacity: 1;
  display: flex;
  justify-content: center;
}
.loader-icon img {
  width: clamp(150px, 23%, 350px);
  height: auto;
  animation: rotation infinite linear;
  animation-duration: 5s;
}
.loader-icon-inactive {
  opacity: 0;
  transform: scale(0);
}
.loader-icon-inactive img {
  animation-duration: 20s;
}
.loader-icon.first.loading {
  transform: scale(1);
}
.loader-bar {
  height: 100%;
  background-color: white;
  transition: transform 2.7s ease-in;
  transform-origin: left;
  transform: scaleX(0);
}
.loader-bar-fast {
  transition: transform 1.3s ease-in;
}
.loader-bar-container {
  opacity: 1;
  width: clamp(250px, 30%, 400px);
  background-color: dimgray;
  height: 6px;
  transform: scale(1);
  transition: all 0.8s ease-out;
}
.loader-bar-container.hidden {
  opacity: 0;
  transform: scale(0);
}
.hero {
  position: relative;
  background-color: #1b1b1b;
  height: clamp(850px, calc(100vh + 220px), 2000px);
}
.hero-icon {
  position: absolute;
  height: 220px;
  bottom: -220px;
  z-index: 1000;
  left: calc(50% - (220px / 2));
  transition: all 0.5s ease-out;
  transition-delay: 0.4s;
  opacity: 0;
}
.hero-icon-inactive {
  opacity: 1;
  animation: anim_heroLogo 0.5s, rotation 50s infinite linear;
  animation-fill-mode: both;
  animation-delay: 0.4s;
}
@keyframes anim_heroLogo {
  0% {
    opacity: 0;
    bottom: -220px;
  }
  100% {
    opacity: 1;
    bottom: -110px;
  }
}
.hero-icon-cancel-init-anim {
  opacity: 1 !important;
  bottom: -110px !important;
}
.hero-lang-switch {
  position: absolute;
  right: 10%;
  top: 40px;
  z-index: 1000;
  background: #000;
  min-width: 75px;
  height: 40px;
  background: rgba(0, 0, 0, 0.3) !important;
}
.hero-lang-switch:hover {
  background: rgba(0, 0, 0, 0.3) !important;
}
.hero-lang-switch.ant-switch-checked {
  background: rgba(0, 0, 0, 0.5) !important;
}
.hero-lang-switch.ant-switch-checked:hover {
  background: rgba(0, 0, 0, 0.5) !important;
}
.hero-lang-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 45px) !important;
}
.hero-lang-switch-sk .ant-switch-handle:before {
  background-image: url(../../../public//images/sk-flag.svg);
}
.hero-lang-switch-en.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 42px) !important;
}
.hero-lang-switch-en .ant-switch-handle {
  width: 44px !important;
  height: 44px !important;
  top: -2px !important;
  left: -2px !important;
}
.hero-lang-switch-en .ant-switch-handle:before {
  background-image: url(../../../public//images/en-flag.svg);
}
.hero-lang-switch .ant-switch-handle {
  width: 50px;
  height: 50px;
  top: -5px;
  left: -5px;
  box-shadow: none !important;
}
.hero-lang-switch .ant-switch-handle:where(
          .css-dev-only-do-not-override-sk7ap8
        ).ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 40px) !important;
}
.hero-lang-switch .ant-switch-handle:before {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-color: transparent;
  box-shadow: none !important;
}
.hero-banners {
  margin: 0 auto;
  position: relative;
  height: 0;
}
.hero-banners-banner-left {
  position: absolute;
  top: 0;
  left: 4%;
  height: 120px;
  width: 140px;
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.hero-banners-banner-left.gold {
  background-image: linear-gradient(120deg, #c29403 0%, #e1bb25 100%);
}
.hero-banners-banner-left.green {
  background-image: linear-gradient(40deg, hsl(73, 87%, 40%) 0%, hsl(76, 92%, 36%) 50%, hsl(79, 96%, 33%) 100%);
}
.hero-banners-banner-left.red {
  background-image: linear-gradient(40deg, #a70707 0%, #ff0100 50%, #ff2f2f 100%);
}
.hero-banners-banner-left-logo {
  height: 50%;
  width: auto;
}
.hero-banners-banner-right {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  /* position: absolute;
          top: 0;
          right: 0; */
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  padding: 20px;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0.4);
}
.hero-banners-banner-right-icon {
  display: flex;
  justify-content: center;
}
.hero-banners-banner-right-icon img,
.hero-banners-banner-right-icon svg {
  height: 18px;
  width: auto;
  fill: white;
  transform: scale(1);
  transition: transform 0.1s ease-in-out;
}
.hero-banners-banner-right-icon img:hover,
.hero-banners-banner-right-icon svg:hover {
  transform: scale(1.2);
}
.hero-banners-banner-right-lang-s {
  position: absolute;
  z-index: 100;
  right: 4px;
  top: 200px;
  transform: rotate(90deg);
  background: #000;
  min-width: 50px;
  height: 28px;
  background: rgba(0, 0, 0, 0.3) !important;
  box-shadow: none !important;
  outline: none !important;
}
.hero-banners-banner-right-lang-s:after {
  box-shadow: none !important;
  outline: none !important;
}
.hero-banners-banner-right-lang-s:hover {
  background: rgba(0, 0, 0, 0.3) !important;
  box-shadow: none !important;
  outline: none !important;
}
.hero-banners-banner-right-lang-s:focus,
.hero-banners-banner-right-lang-s:active {
  box-shadow: none !important;
  outline: none !important;
}
.hero-banners-banner-right-lang-s.ant-switch-checked {
  background: rgba(0, 0, 0, 0.5) !important;
  box-shadow: none !important;
  outline: none !important;
}
.hero-banners-banner-right-lang-s.ant-switch-checked:hover {
  background: rgba(0, 0, 0, 0.5) !important;
}
.hero-banners-banner-right-lang-s.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 30px) !important;
}
.hero-banners-banner-right-lang-s-sk .ant-switch-handle:before {
  background-image: url(../../../public//images/sk-flag.svg);
}
.hero-banners-banner-right-lang-s-sk.ant-switch-checked .ant-switch-handle {
  width: 30px !important;
  height: 30px !important;
  top: -1px;
}
.hero-banners-banner-right-lang-s-sk.ant-switch-checked .ant-switch-handle:before {
  background-image: url(../../../public//images/en-flag.svg);
}
.hero-banners-banner-right-lang-s-en.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 28px) !important;
}
.hero-banners-banner-right-lang-s-en .ant-switch-handle {
  width: 30px !important;
  height: 30px !important;
  top: -1px !important;
  left: -1px !important;
  box-shadow: none !important;
  outline: none !important;
}
.hero-banners-banner-right-lang-s-en .ant-switch-handle:before {
  background-image: url(../../../public//images/en-flag.svg);
}
.hero-banners-banner-right-lang-s-en.ant-switch-checked .ant-switch-handle {
  width: 35px !important;
  height: 35px !important;
  top: -3px !important;
  margin-left: -3px;
}
.hero-banners-banner-right-lang-s-en.ant-switch-checked .ant-switch-handle:before {
  background-image: url(../../../public//images/sk-flag.svg);
}
.hero-banners-banner-right-lang-s .ant-switch-handle {
  width: 35px;
  height: 35px;
  top: -3px;
  left: -3px;
  box-shadow: none !important;
  outline: none !important;
}
.hero-banners-banner-right-lang-s .ant-switch-handle:where(
                .css-dev-only-do-not-override-sk7ap8
              ).ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 28px) !important;
}
.hero-banners-banner-right-lang-s .ant-switch-handle:before {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-color: transparent;
  box-shadow: none !important;
  transform: rotate(-90deg);
}
.hero-banners-banner-right-lang-s .ant-switch-inner {
  box-shadow: none !important;
  outline: none !important;
}
.hero-banners-banner-right-lang-s .ant-switch-inner:after {
  box-shadow: none !important;
  outline: none !important;
}
.hero-slider {
  height: calc(100% - 220px);
  overflow: visible;
}
.hero-slider-slide {
  z-index: 2000;
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow: visible;
}
.hero-slider-slide .transparent {
  height: 100%;
}
.hero-slider-slide.slide-1 {
  /*background-image: url(~"@{public-path}/images/hero_01.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white !important;
}
.hero-slider-slide.slide-1 .container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.hero-slider-slide.slide-1 .container .headings {
  text-align: center;
  text-transform: uppercase;
  display: block;
  z-index: 1000;
  padding: 0 15px;
}
.hero-slider-slide.slide-1 .container .headings h1 {
  opacity: 0;
  font-size: 5.625rem;
  letter-spacing: -0.3125rem;
  font-weight: bold;
  margin: 0 0 10px 0;
  line-height: 1;
}
.hero-slider-slide.slide-1 .container .headings h2 {
  opacity: 0;
  line-height: 1;
  font-size: 2.8125rem;
  letter-spacing: -0.125rem;
  font-weight: bold;
  margin: 0;
}
.hero-slider-slide.slide-1 .container-button-wrapper {
  opacity: 0;
}
.hero-slider-slide.slide-1 .container .button-container {
  display: flex;
  justify-content: center;
  padding-top: clamp(100px, 10vh, 150px);
}
.hero-slider-slide.slide-2 {
  background-image: url(../../../public//images/hero_03.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.hero-slider-slide.slide-2 .slide-2 {
  position: relative;
}
.hero-slider-slide.slide-2 .slide-2-content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.hero-slider-slide.slide-2 .slide-2-container {
  max-width: 1700px;
  margin: 0 auto;
}
.hero-slider-slide.slide-2 .slide-2-left {
  position: absolute;
  height: 100%;
  width: 50%;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
}
.hero-slider-slide.slide-2 .slide-2-left-content {
  position: relative;
  height: auto;
  max-width: calc(1700px / 2);
  float: right;
  margin-top: clamp(calc(-1000px * 0.15), calc(-100vh * 0.15), calc(-1500px * 0.15));
}
.hero-slider-slide.slide-2 .slide-2-left-content-heading {
  text-transform: uppercase;
  padding: 0 0 0 7.5%;
  color: white;
  line-height: 1;
  z-index: 1000;
}
.hero-slider-slide.slide-2 .slide-2-left-content-heading.first {
  font-size: 4.125rem;
  letter-spacing: -0.175rem;
  font-weight: bold;
}
.hero-slider-slide.slide-2 .slide-2-left-content-heading.second {
  font-size: 3.75rem;
  letter-spacing: -0.15625rem;
  font-weight: bold;
}
.hero-slider-slide.slide-2 .slide-2-left-content-heading.third {
  font-size: 2.8125rem;
  letter-spacing: -0.125rem;
  font-weight: bold;
}
.hero-slider-slide.slide-2 .slide-2-left-content-logo {
  max-width: 75%;
  padding: 0 12.5% 0 7.5%;
  height: auto;
  pointer-events: none;
}
.hero-slider-slide.slide-2 .slide-2-left-content-stars-white {
  padding: 35px 0 30px 7.5%;
  max-width: 212px;
  pointer-events: none;
}
.hero-slider-slide.slide-2 .slide-2-left-content-bottom-container {
  margin-top: clamp(calc(850px * 0.06), calc(100vh * 0.06), calc(2000px * 0.06));
  display: flex;
  align-items: center;
}
.hero-slider-slide.slide-2 .slide-2-left-content-bottom-container .button-container {
  margin-left: 7.5%;
}
.hero-slider-slide.slide-2 .slide-2-left-content-bottom-container .primary-btn {
  /*
                  &-container {
                    margin-left: 7.5%;
                  } */
}
.hero-slider-slide.slide-2 .slide-2-left-content-bottom-container .primary-btn button {
  background-image: linear-gradient(40deg, hsl(73, 87%, 40%) 0%, hsl(76, 92%, 36%) 50%, hsl(79, 96%, 33%) 100%);
}
.hero-slider-slide.slide-2 .slide-2-left-content-bottom-container .primary-btn button:before {
  background-image: linear-gradient(40deg, hsl(79, 96%, 33%) 0%, hsl(76, 92%, 36%) 50%, hsl(73, 87%, 40%) 100%);
}
.hero-slider-slide.slide-2 .slide-2-left-content-bottom-container .primary-btn button p {
  color: #fff;
}
.hero-slider-slide.slide-2 .slide-2-left-content-bottom-container .primary-btn p {
  color: #fff;
}
.hero-slider-slide.slide-2 .slide-2-left-content-bottom-container-stars-green {
  display: inline-block;
  max-width: 212px;
  margin-left: 10%;
  pointer-events: none;
}
.hero-slider-slide.slide-2 .slide-2-right {
  position: absolute;
  height: 100%;
  width: 50%;
  display: inline-flex;
  right: 0;
}
.hero-slider-slide.slide-2 .slide-2-right-content {
  max-width: calc(1700px / 2);
}
.hero-slider-slide.slide-2 .slide-2-right-content-image {
  padding: 7.5% 0 7.5% 15%;
  max-height: 85%;
  width: auto;
  pointer-events: none;
}
.hero-slider-slide.slide-2 .slide-2-stars {
  height: auto;
  width: 212px;
  position: absolute;
  left: calc(50% - 106px);
  bottom: -120px;
}
.hero-slider-slide.slide-3 .slide-3-content-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff;
}
.hero-slider-slide.slide-3 .slide-3-left {
  display: inline-block;
  height: 100%;
  background-color: black;
  width: calc((100% - 1700px) / 2);
  overflow: hidden;
}
.hero-slider-slide.slide-3 .slide-3-right {
  display: inline-block;
  height: 100%;
  background-color: white;
  width: calc((100% - 1700px) / 2);
  overflow: hidden;
  min-height: 1000px;
}
.hero-slider-slide.slide-3 .slide-3-content {
  position: relative;
  z-index: 100;
  display: inline-block;
  background-color: white;
  height: 100%;
  max-width: 1700px;
  width: 100%;
  margin: 0 auto;
}
.hero-slider-slide.slide-3 .slide-3-content-image-left {
  pointer-events: none;
  position: relative;
  display: inline-block;
  height: 100%;
  min-height: 1000px;
  width: auto;
  margin-left: -22%;
  min-width: 300px;
}
.hero-slider-slide.slide-3 .slide-3-content-image-right-container {
  position: relative;
  background-color: transparent;
  height: 100%;
  width: calc(1700px / 2);
  position: absolute;
  top: 0;
  right: 10%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.hero-slider-slide.slide-3 .slide-3-content-image-right-container-logo {
  pointer-events: none;
  width: 600px;
  position: relative;
  height: auto;
}
.hero-slider-slide.slide-3 .slide-3-content-image-right-container-image {
  pointer-events: none;
  height: 1500px;
  width: auto;
  margin-left: -220px;
  margin-top: -250px;
}
.hero-slider-slide.slide-3 .slide-3-stars {
  height: auto;
  width: 212px;
  position: absolute;
  left: calc(50% - 106px);
  bottom: -120px;
}
.our-goal {
  background-color: #1b1b1b;
  position: relative;
  z-index: 1000;
  overflow: hidden;
  margin-top: -1px;
}
.our-goal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  color: #fefefe;
  background-image: url(../../../public//images/glow.png);
  background-color: #1b1b1b;
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 60% 200%;
  overflow: hidden;
}
.our-goal-content-glow {
  position: absolute;
  bottom: -65%;
  left: 0;
  height: 150%;
  width: 50%;
  z-index: 300;
}
.our-goal-content-stars-golden {
  height: auto;
  width: 212px;
}
.our-goal-content-stars-golden.first {
  margin-top: 100px;
  opacity: 0;
  transition: all 0.8s ease-in-out;
}
.our-goal-content-stars-golden.first.active {
  opacity: 1;
}
.our-goal-content-stars-golden.second {
  opacity: 0;
  margin: 120px 0;
}
.our-goal-content-stars-golden-container.first {
  position: absolute;
  top: 0;
}
.our-goal-content-stars-golden-container.second {
  position: relative;
}
.our-goal-content-heading {
  opacity: 0;
  font-size: 4.375rem;
  letter-spacing: -0.125rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 0 !important;
  z-index: 1000;
  background-color: transparent;
  background-image: linear-gradient(to right top, #bb9305, #ffe684);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
.our-goal-content-heading::selection {
  color: white;
  -webkit-text-fill-color: white;
  -moz-text-fill-color: white;
  background-color: #cea913 !important;
}
.our-goal-content-paragraph {
  opacity: 0;
  font-size: 2.1875rem;
  letter-spacing: -0.09375rem;
  margin-top: 50px;
  font-weight: bold;
  max-width: 900px;
  text-align: center;
  z-index: 1000;
}
.our-experience {
  background-color: #1b1b1b;
  position: relative;
}
.our-experience .react-parallax-bgimage {
  margin-top: -4%;
}
.our-experience-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 250px 0;
}
.our-experience-content ::selection {
  background: #bd271f;
}
.our-experience-content-container {
  width: 100%;
  margin: 0 auto;
  max-width: 1500px;
  color: #ffffff;
  z-index: 3000;
}
.our-experience-content-paragraph {
  opacity: 0;
  display: inline-block;
  font-size: 2.0625rem;
  letter-spacing: -0.08125rem;
  font-weight: bold;
  max-width: 1000px;
  text-align: left;
  z-index: 1000;
  margin: 0 35px;
  width: 55%;
  z-index: 500;
  transition: all 0s;
  transform: translateX(-15%);
}
.our-experience-content-paragraph.animate {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.65s cubic-bezier(0.5, 1, 0.89, 1);
  transition-delay: 250ms;
}
.our-experience-content-paragraph-title {
  opacity: 0;
  display: block;
  font-size: 3.75rem;
  letter-spacing: -0.1875rem;
  padding: 0 0 10px;
  z-index: 500;
  margin: 0 35px;
  transform: translateX(-15%);
  transition: all 0s;
}
.our-experience-content-paragraph-title.animate {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.6s cubic-bezier(0.5, 1, 0.89, 1);
}
.our-focus {
  min-height: 480px;
  background-color: #bd271f;
}
.our-focus ::selection {
  background: transparent !important;
}
.our-focus-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 100;
}
.our-focus-content-heading {
  opacity: 0;
  font-size: 3.4375rem;
  letter-spacing: -0.125rem;
  font-weight: bold;
  color: white;
  margin: 110px 15px;
  z-index: 1000;
  text-align: center;
}
.our-focus-content-heading::selection {
  background: black !important;
}
.our-focus-content-logo {
  width: 500px;
  opacity: 0;
}
.our-focus-content-suspensions {
  max-width: 1400px;
  width: 100%;
  margin: 90px 20px 130px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.our-focus-content-suspensions-star {
  opacity: 0;
  max-height: 20px;
  width: auto;
  transform: scale(3);
  transition: all 0s;
}
.our-focus-content-suspensions-star.animate {
  transition: all 1s ease-in;
  transform: scale(1) rotate(360deg);
  opacity: 1;
}
.our-focus-content-suspensions-item {
  opacity: 0;
  height: 420px;
  position: relative;
  flex: 0 0 calc(100% / 4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.our-focus-content-suspensions-item-image {
  height: 100%;
  width: auto;
}
.our-focus-content-suspensions-item-image-hover-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.our-focus-content-suspensions-item-image.hover {
  opacity: 0;
  transition: opacity 0.8s ease-out;
  height: 100%;
  width: auto;
}
.our-focus-content-suspensions-item-image.hover:hover {
  opacity: 1;
  transition: opacity 0.6s ease-out;
}
.our-focus-content-suspensions-item-image.touch {
  opacity: 0;
  transition: opacity 0.6s ease-out;
  height: 100%;
  width: auto;
}
.our-focus-content-suspensions-item-image.touch.on-click-effect {
  opacity: 1;
}
.our-focus-content-paragraph {
  opacity: 0;
  display: inline-block;
  color: #610100;
  font-size: 1.375rem;
  letter-spacing: -0.05rem;
  margin: 0 25px 100px 25px;
  z-index: 1000;
  text-align: center;
  font-weight: 600;
}
.our-focus-content-paragraph-container ::selection {
  background: white !important;
}
.service-content {
  z-index: 1500;
  overflow-x: visible;
}
.service-content ::selection {
  background: #e9b835 !important;
}
.service-content-top {
  display: flex;
  flex-direction: row;
  max-width: 1700px;
  margin: 0 auto;
  z-index: 1500;
  position: relative;
}
.service-content-top-left {
  width: 50%;
  color: #121212;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  height: calc(680px - (22.92 * 6.8px));
  z-index: 2000;
}
.service-content-top-left-heading {
  opacity: 0;
  font-size: 3.125rem;
  letter-spacing: -0.15625rem;
  text-transform: uppercase;
  padding: 0 0 40px 40px;
  transition: all 0s;
  transform: translateX(-15%);
}
.service-content-top-left-heading.animate {
  opacity: 1;
  transition: all 0.6s cubic-bezier(0.5, 1, 0.89, 1);
  transform: translateX(0);
}
.service-content-top-left-paragraph {
  opacity: 0;
  font-size: 1.625rem;
  letter-spacing: -0.03125rem;
  padding: 0 0 0 40px;
  font-weight: bold;
  transition: all 0s;
  transform: translateX(-15%);
}
.service-content-top-left-paragraph.animate {
  opacity: 1;
  transition: all 0.6s cubic-bezier(0.5, 1, 0.89, 1);
  transform: translateX(0);
  transition-delay: 250ms;
}
.service-content-top-right {
  width: 50%;
  position: relative;
  z-index: 1500;
}
.service-content-top-right-image {
  opacity: 1;
  transform: translateY(60%);
  transition: all 0.6s ease-in;
  position: absolute;
  z-index: 2000;
  right: -5%;
  top: 11%;
  opacity: 0;
  filter: blur(12px);
  width: 75%;
}
.service-content-top-right-image-anim {
  animation: jogi;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
  animation-delay: 0.4s;
}
@keyframes jogi {
  0% {
    opacity: 0;
    filter: blur(12px);
    transform: scale(1.5);
  }
  100% {
    filter: blur(0px);
    transform: scale(1.3);
    opacity: 1;
  }
}
.service-content-top-right-image-bck {
  position: absolute;
  z-index: 50;
  inset: 0;
  left: 5%;
  width: 60%;
  filter: blur(11px);
  transform: scale(1.5);
  opacity: 0;
}
.service-content-top-right-image-bck-anim {
  animation: bck-2;
  animation-duration: 0.9s;
  animation-fill-mode: forwards;
}
@keyframes bck-2 {
  0% {
    filter: blur(11px);
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    filter: blur(0px);
    transform: scale(1);
    opacity: 1;
  }
}
.service-content-top-right-image-bck-blust {
  position: absolute;
  z-index: 51;
  inset: 0;
  left: 5%;
  width: 60%;
  opacity: 1;
  filter: blur(9px);
  transform: scale(0);
}
.service-content-top-right-image-bck-blust-anim {
  animation: blast-first;
  animation-duration: 0.8s;
  animation-fill-mode: both;
}
@keyframes blast-first {
  0% {
    opacity: 1;
    filter: blur(9px);
    transform: scale(0);
  }
  100% {
    filter: blur(0px);
    transform: scale(1.7);
    opacity: 0;
  }
}
.service-content-top-right-bcks {
  position: absolute;
  overflow: hidden;
  width: 300%;
  height: 300%;
  inset: 0;
  left: -50%;
}
.service-content-top-right-bcks .normal {
  display: block;
}
.service-content-top-right-bcks .rotated {
  display: none;
}
.service-content-top-anim {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.service-content-top-anim-bck {
  position: absolute;
  z-index: 50;
  width: 100%;
  left: 30%;
  overflow: hidden;
}
.service-content-top-anim-bck-anim {
  animation: jogi-bck;
  animation-duration: 0.8s;
  animation-fill-mode: both;
  animation-delay: 0.1s;
}
@keyframes jogi-bck {
  0% {
    filter: blur(11px);
    transform: scale(1.5);
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    filter: blur(0px);
    transform: scale(1);
  }
}
.service-content-top-anim-bck-blust {
  position: absolute;
  z-index: 50;
  width: 100%;
  inset: 0;
  left: 30%;
  overflow: hidden;
}
.service-content-top-anim-bck-blust-anim {
  animation: jogi-bck-blast;
  animation-duration: 0.9s;
  animation-fill-mode: both;
}
@keyframes jogi-bck-blast {
  0% {
    opacity: 1;
    filter: blur(9px);
    transform: scale(0);
  }
  100% {
    filter: blur(0px);
    transform: scale(1.7);
    opacity: 0;
  }
}
.service-content-squares {
  background-color: white;
  max-width: calc(1900px - (15px * 2));
  width: 100%;
  margin: 0 auto;
  padding: 15px;
  display: grid;
  grid-template-columns: repeat(3, minmax(450px, 1fr));
  grid-auto-rows: minmax(450px, 1fr);
  row-gap: 15px;
  column-gap: 15px;
  box-sizing: border-box;
  z-index: 2050;
  position: relative;
}
.service-content-squares .square-container {
  opacity: 0;
  position: relative;
  background-color: #1c1c1b;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  z-index: 2000;
  -webkit-mask-image: linear-gradient(315deg, #000 40%, rgba(0, 0, 0, 0.8) 50%, #000 60%);
  mask-image: linear-gradient(315deg, #000 40%, rgba(0, 0, 0, 0.8) 50%, #000 60%);
  -webkit-mask-position: 100%;
  mask-position: 100%;
  -webkit-mask-size: 600%;
  mask-size: 600%;
}
.service-content-squares .square-container:hover {
  -webkit-mask-position: 0;
  mask-position: 0;
  opacity: 1;
  transition: mask-position 1.4s ease, -webkit-mask-position 1.4s ease;
}
.service-content-squares .square-container .heading {
  color: white;
  position: absolute;
  font-size: 6.875rem;
  line-height: 5.9375rem;
  letter-spacing: -0.3125rem;
  bottom: -2.5%;
  left: -1.5%;
  font-weight: 900;
  text-transform: uppercase;
  opacity: 0;
  transition: opacity 0.25s ease-out;
}
.service-content-squares .square-container:hover .heading,
.service-content-squares .square-container:focus .heading,
.service-content-squares .square-container:active .heading {
  opacity: 1;
}
.service-content-squares .square-container:hover .square-content-bck,
.service-content-squares .square-container:focus .square-content-bck,
.service-content-squares .square-container:active .square-content-bck {
  opacity: 0;
}
.service-content-squares .square-container:hover .square-content .square-icon,
.service-content-squares .square-container:focus .square-content .square-icon,
.service-content-squares .square-container:active .square-content .square-icon {
  transform: translate(-150%, -150%) rotate(-45deg);
  opacity: 1;
  animation-name: fadeOut;
  animation-fill-mode: both;
  animation-duration: 0.5s;
}
.service-content-squares .square-container:hover .square-content .square-heading,
.service-content-squares .square-container:focus .square-content .square-heading,
.service-content-squares .square-container:active .square-content .square-heading {
  transform: translateX(-100%);
  opacity: 1;
  animation-name: fadeOut;
  animation-fill-mode: both;
  animation-duration: 0.5s;
}
.service-content-squares .square-container:hover .square-content .square-paragraph,
.service-content-squares .square-container:focus .square-content .square-paragraph,
.service-content-squares .square-container:active .square-content .square-paragraph {
  transform: translateY(100%);
  opacity: 1;
  animation-name: fadeOut;
  animation-fill-mode: both;
  animation-duration: 0.5s;
}
.service-content-squares .square-container:hover .square-content .square-star,
.service-content-squares .square-container:focus .square-content .square-star,
.service-content-squares .square-container:active .square-content .square-star {
  transform: rotate(90deg) translateY(-300%) scale(2.5);
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.service-content-squares .square-container:hover.animate,
.service-content-squares .square-container:focus.animate,
.service-content-squares .square-container:active.animate {
  opacity: 0;
  transition: all 0.25s cubic-bezier(0.5, 1, 0.89, 1);
  transition-delay: 0.25s;
}
.service-content-squares .square-container .square-content-bck {
  position: absolute;
  inset: 0;
  z-index: 1000;
  background-size: cover;
  background-position: center;
  transition: 0.3s ease-out;
  opacity: 1;
}
.service-content-squares .square-container .square-content {
  position: relative;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 1;
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;
  z-index: 2000;
  inset: 0;
}
.service-content-squares .square-container .square-content .square-star {
  height: 300px;
  width: 300px;
  position: absolute;
  z-index: 3000;
  left: 15px;
  top: 70px;
  opacity: 1;
  transform: translateX(0) translateY(0) rotate(0);
  transition: 0.7s ease-out;
  pointer-events: none;
  transition-delay: 0s;
  scale: 1;
}
.service-content-squares .square-container .square-content .square-icon {
  pointer-events: none;
  height: 100px;
  width: 100px;
  z-index: 500;
  position: absolute;
  top: 50px;
  left: 50px;
  opacity: 1;
  transform: translate(0);
  transition: all 0.5s ease-out;
}
.service-content-squares .square-container .square-content .square-icon.paint {
  width: 73px;
}
.service-content-squares .square-container .square-content .square-text-container {
  pointer-events: none;
  margin-top: 270px;
  margin-left: 50px;
}
.service-content-squares .square-container .square-content .square-heading {
  pointer-events: none;
  padding-top: 15px;
  color: white;
  text-transform: uppercase;
  line-height: 4.6875rem;
  letter-spacing: -0.125rem;
  font-weight: bold;
  z-index: 3000;
  font-size: 4rem;
  opacity: 1;
  transform: translateX(0);
  transition-delay: 0s;
  transition: all 0.5s ease-out;
}
.service-content-squares .square-container .square-content .square-paragraph {
  pointer-events: none;
  padding-top: 15px;
  color: white;
  font-weight: bold;
  z-index: 3000;
  font-style: normal;
  max-width: 75%;
  padding-bottom: 45px;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.75rem;
  letter-spacing: -0.03em;
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0s;
  transition: all 0.3s ease-out;
}
.service-content-squares .square-container.setup {
  background-color: #1b1b1b;
}
.service-content-squares .square-container.setup-bck {
  background-image: url(../../../public//images/square-setup.jpg);
}
.service-content-squares .square-container.setup .square-content-bck {
  background-image: url(../../../public//images/square-setup-bw.jpg);
}
.service-content-squares .square-container.parts {
  background-color: #1b1b1b;
}
.service-content-squares .square-container.parts-bck {
  background-image: url(../../../public//images/square-parts.jpg);
}
.service-content-squares .square-container.parts .square-content:hover .square-content-bck {
  opacity: 0;
}
.service-content-squares .square-container.parts .square-content-bck {
  background-image: url(../../../public//images/square-parts-bw.jpg);
}
.service-content-squares .square-container.custom {
  background-color: #1b1b1b;
}
.service-content-squares .square-container.custom-bck {
  background-image: url(../../../public//images/square-custom.jpg);
}
.service-content-squares .square-container.custom .square-content-bck {
  background-image: url(../../../public//images/square-custom-bw.jpg);
}
.service-content-squares .square-container.upgrades {
  background-color: #1b1b1b;
}
.service-content-squares .square-container.upgrades-bck {
  background-image: url(../../../public//images/square-upgrades.jpg);
}
.service-content-squares .square-container.upgrades .square-content-bck {
  background-image: url(../../../public//images/square-upgrades-bw.jpg);
}
.service-content-squares .square-container.professional {
  background-color: #1b1b1b;
}
.service-content-squares .square-container.professional-bck {
  background-image: url(../../../public//images/square-professional.jpg);
}
.service-content-squares .square-container.professional .square-content-bck {
  background-image: url(../../../public//images/square-professional-bw.jpg);
}
.service-content-squares .square-container.paint {
  background-color: #1b1b1b;
}
.service-content-squares .square-container.paint-bck {
  background-image: url(../../../public//images/square-paint.jpg);
}
.service-content-squares .square-container.paint .square-content-bck {
  background-image: url(../../../public//images/square-paint-bw.jpg);
}
.contact {
  width: 100% !important;
}
.contact-content {
  background: #121212;
  background-image: url(../../../public//images/contact-bck.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding-left: 20px;
  padding-right: 20px;
  padding: 5% 0;
  max-width: 100%;
  padding-bottom: 5%;
}
.contact-content-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 0 !important;
}
.contact-content-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: clamp(350px, 60%, 700px);
  max-width: 100%;
  gap: 50px;
}
.contact-content-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
}
.contact-content-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
}
.contact-content-heading h1 {
  font-size: 4.0625rem;
  text-transform: uppercase;
  line-height: 100%;
  color: #fff;
}
.contact-content-heading img {
  width: 32%;
  margin-top: 40px;
}
.contact-content-form {
  width: 100% !important;
  padding: 30px;
  border-radius: 3px;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 30px;
  opacity: 0;
}
.contact-content-form > div:first-child {
  display: flex;
  gap: 30px;
}
.contact-content-form > div:last-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contact-content-form input,
.contact-content-form textarea {
  background: #121212;
  color: #fff;
  padding: 15px;
  font-size: 0.8125rem;
  font-weight: 600;
}
.contact-content-form input::placeholder,
.contact-content-form textarea::placeholder {
  font-size: 0.8125rem;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
}
.contact-content-form input:focus,
.contact-content-form textarea:focus,
.contact-content-form input:hover,
.contact-content-form textarea:hover,
.contact-content-form input:active,
.contact-content-form textarea:active {
  box-shadow: none;
  outline: none;
  border-color: #111;
}
.contact-content-form .notifications {
  position: relative;
  width: 100%;
  height: 40px;
}
.contact-content-form .notifications .content {
  padding: 0 15px;
  height: 40px;
  border-radius: 6px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  transition: opacity 0.3s ease-out;
  position: absolute;
  inset: 0;
  font-weight: 600;
}
.contact-content-form .notifications .content.active {
  opacity: 1;
}
.contact-content-form .notifications .content.error {
  background-color: #bd271f;
}
.contact-content-form .notifications .content.success {
  background-color: #53b428;
}
.contact-content-form .notifications .content svg:last-child {
  width: 18px;
  height: auto;
  cursor: pointer;
}
.contact-content-form .has-error {
  border: 3px solid #bd271f;
  background: #323232;
}
.contact-content-form input {
  height: 40px;
}
.contact-content-form textarea {
  height: 120px;
  min-height: 120px;
  max-height: 400px;
}
.contact-content-form button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  font-size: 0.9375rem;
  padding: 18px 40px;
  border: none;
  border-radius: 8px;
  background-image: linear-gradient(50deg, hsl(45, 100%, 34%) 0%, hsl(47, 91%, 39%) 50%, hsl(48, 83%, 44%) 100%);
  background-size: 100%;
  position: relative;
  z-index: 100;
}
.contact-content-form button:before {
  background-image: linear-gradient(50deg, hsl(48, 83%, 44%) 0%, hsl(47, 91%, 39%) 50%, hsl(45, 100%, 34%) 100%);
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  z-index: -100;
  transition: opacity 0.6s;
  border-radius: 8px;
}
.contact-content-form button p {
  margin-top: 1px;
}
.contact-content-form button:hover:before {
  opacity: 1;
}
.contact-content-logo {
  width: clamp(300px, 17%, 650px);
  margin-top: 3%;
  opacity: 0;
}
.contact-content-jogi {
  width: 30%;
  margin-top: auto;
  display: none;
  opacity: 0;
  margin-top: 0 !important;
}
.footer {
  background: #121212;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -1px;
}
.footer .orka-logo-wrapper {
  display: inline-block;
  width: clamp(110px, 7%, 160px);
  position: relative;
  margin-bottom: clamp(70px, 10%, 150px);
  opacity: 0;
}
.footer .orka-logo-wrapper > svg {
  fill: #fff;
  /*position: relative;
    padding-bottom: clamp(80px, 10%, 250px);
    opacity: 0; */
  transition: transform 0.3s ease-out;
}
.footer .orka-logo-wrapper > svg:hover {
  transform: scale(1.1);
}
.footer-content {
  width: clamp(300px, 33%, 800px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #121212;
  padding: clamp(55px, 4%, 100px);
  padding-bottom: clamp(17px, 2%, 80px);
  gap: clamp(55px, 50px, 60px);
}
.footer-content h1 {
  font-size: 3.125rem;
  text-transform: uppercase;
  letter-spacing: -0.0625rem;
  background-color: transparent;
  background-image: linear-gradient(to right top, #bb9305, #ffe684);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
  opacity: 0;
}
.footer-content .content {
  display: flex;
  align-items: center;
  opacity: 0;
}
.footer-content .content div:first-child {
  width: 250px;
  text-align: right;
  line-height: 1.5625rem;
}
.footer-content .content div:last-child {
  width: 250px;
  text-align: left;
  line-height: 1.5625rem;
}
.footer-content .content a {
  display: block;
  font-size: 1.125rem;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}
.footer-content .content p {
  font-size: 1.125rem;
  font-weight: 600;
}
.footer-content .content > p {
  margin-top: 1px;
}
.footer-content .content img {
  width: 55px;
}
.footer-content .icons {
  margin-top: 10px;
  opacity: 0;
}
.footer-content .icons .links-icon {
  margin-left: 3px;
  gap: 15px;
}
.footer-content .icons .links-icon svg {
  width: 25px;
  height: 25px;
  fill: #fff;
}
.footer-content .icons .links-icon img {
  width: 12px;
  height: 12px;
}
.footer-content .icons .links-icon a {
  color: #fff;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.anim_fadeIn {
  animation-name: anim_fadeIn;
  animation-duration: 1s;
  animation-fill-mode: both;
}
@keyframes anim_fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.anim_fadeInLeft {
  animation-name: anim_fadeInLeft;
  animation-duration: 1s;
  animation-fill-mode: both;
}
@keyframes anim_fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-120px);
  }
  60% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
.anim_fadeInRight {
  animation-name: anim_fadeInRight;
  animation-duration: 1s;
  animation-fill-mode: both;
}
@keyframes anim_fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(120px);
  }
  60% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
.anim_fadeInTop {
  animation-name: anim_fadeInTop;
  animation-duration: 1s;
  animation-fill-mode: both;
}
@keyframes anim_fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-90px);
  }
  60% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
.anim_fadeInBottom {
  animation-name: anim_fadeInBottom;
  animation-duration: 1s;
  animation-fill-mode: both;
}
@keyframes anim_fadeInBottom {
  0% {
    opacity: 0;
    transform: translateY(70px);
  }
  60% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
.anim_hero-headings-h1 {
  animation-name: anim_hero-headings-h1;
  animation-duration: 0.8s;
  animation-fill-mode: both;
}
@keyframes anim_hero-headings-h1 {
  0% {
    opacity: 0;
    transform: translateX(-130px);
  }
  60% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
.anim_hero-headings-h2 {
  animation-name: anim_hero-headings-h2;
  animation-duration: 0.7s;
  animation-fill-mode: both;
}
@keyframes anim_hero-headings-h2 {
  0% {
    opacity: 0;
    transform: translateX(-115px);
  }
  60% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.anim_hero-button {
  animation-name: anim_hero-button;
  animation-duration: 0.8s;
  animation-fill-mode: both;
}
@keyframes anim_hero-button {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  60% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
.anim_contact-jogi {
  animation: anim_contact_jogi 1s /*, anim_contact_jogi_growing 40s */;
  animation-fill-mode: both;
}
@keyframes anim_contact_jogi {
  0% {
    opacity: 0;
    transform: scale(0.9);
    filter: contrast(300%) brightness(200%) blur(10px);
  }
  /*
  80% {

  } */
  100% {
    opacity: 1;
    filter: contrast(100%) brightness(100%) blur(0);
    transform: scale(1);
  }
}
@keyframes anim_contact_jogi_growing {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
.anim_contactLogo {
  animation-name: anim_contactLogo;
  animation-duration: 0.8s;
  animation-fill-mode: both;
}
@keyframes anim_contactLogo {
  0% {
    opacity: 0;
    transform: scale(0.5) rotate(20deg);
  }
  20% {
    transform: scale(0.5) rotate(20deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(0deg);
  }
}
@media screen and (max-width: 5000px) {
  .contact-content {
    background-size: contain;
    height: 5050px;
    padding-bottom: 0;
  }
  .contact-content-top {
    gap: 150px;
  }
  .contact-content-bottom {
    margin-top: 0%;
    margin-bottom: 22%;
  }
  .contact-content-logo {
    width: 45%;
    margin-bottom: 40%;
  }
  .contact-content-jogi {
    width: 80%;
    display: block;
  }
  .footer-content {
    transform: translateY(-220%);
    margin-bottom: -300px;
  }
}
@media screen and (max-width: 4500px) {
  .contact-content {
    background-size: contain;
    height: 4550px;
    /*
      &-jogi {
        margin-top: 17%; // 15
      } */
  }
  .contact-content-bottom {
    margin-bottom: 22%;
  }
  .contact-content-logo {
    width: 35%;
    margin-bottom: 38%;
  }
  .contact-content-jogi {
    width: 70%;
  }
  .footer-content {
    transform: translateY(-200%);
  }
}
@media screen and (max-width: 4000px) {
  .contact-content {
    background-size: contain;
    height: 4000px;
  }
  .contact-content-bottom {
    margin-bottom: 20%;
  }
  .contact-content-logo {
    width: 30%;
    margin-bottom: 35%;
  }
  .contact-content-jogi {
    width: 65%;
  }
  .footer-content {
    margin-bottom: -200px;
    transform: translateY(-170%);
  }
}
@media screen and (max-width: 3850px) {
  .contact-content {
    background-size: contain;
    height: 3750px;
  }
  .contact-content-bottom {
    margin-bottom: 20%;
  }
  .contact-content-logo {
    width: 23%;
    margin-bottom: 30%;
  }
  .contact-content-jogi {
    width: 60%;
  }
  .footer-content {
    transform: translateY(-150%);
  }
}
@media screen and (max-width: 3400px) {
  .contact-content {
    height: 3300px;
  }
  .contact-content-bottom {
    margin-bottom: 20%;
  }
  .contact-content-logo {
    width: 23%;
    margin-bottom: 25%;
  }
  .contact-content-jogi {
    width: 50%;
  }
  .footer-content {
    transform: translateY(-130%);
  }
}
@media screen and (max-width: 3200px) {
  .contact-content {
    height: 3300px;
  }
  .contact-content-bottom {
    margin-bottom: 25%;
  }
  .contact-content-logo {
    width: 23%;
    margin-bottom: 25%;
  }
  .contact-content-jogi {
    width: 50%;
  }
  .footer-content {
    transform: translateY(-130%);
  }
}
@media screen and (max-width: 3000px) {
  .contact-content {
    height: 2930px;
  }
  .contact-content-bottom {
    margin-bottom: 20%;
  }
  .contact-content-logo {
    margin-top: 0;
    width: 23%;
    margin-bottom: 23%;
  }
  .contact-content-jogi {
    width: 45%;
  }
  .footer-content {
    transform: translateY(-100%);
    margin-bottom: -100px;
  }
}
@media screen and (max-width: 2800px) {
  .contact-content {
    height: 2750px;
  }
  .contact-content-bottom {
    margin-bottom: 21%;
  }
  .contact-content-logo {
    width: 23%;
    margin-bottom: 21%;
  }
  .contact-content-jogi {
    width: 38%;
  }
  .footer-content {
    transform: translateY(-70%);
  }
}
@media screen and (max-width: 2600px) {
  .contact-content {
    height: 2530px;
  }
  .contact-content-bottom {
    margin-bottom: 20%;
  }
  .contact-content-logo {
    width: 23%;
    margin-bottom: 22%;
  }
  .contact-content-jogi {
    width: 35%;
    margin-top: 3%;
  }
  .footer-content {
    margin-bottom: -50px;
  }
}
@media screen and (max-width: 2400px) {
  .contact-content {
    height: 2300px;
    padding-top: 150px;
  }
  .contact-content-inner {
    gap: 100px;
  }
  .contact-content-bottom {
    margin-bottom: 20%;
  }
  .contact-content-heading h1 {
    font-size: 4.0625rem;
  }
  .contact-content-heading img {
    margin-top: 40px;
  }
  .contact-content-logo {
    width: 23%;
    margin-bottom: 18%;
  }
  .contact-content-jogi {
    width: 30%;
    margin-top: 8%;
  }
  .footer-content {
    margin-bottom: -50px;
  }
}
@media screen and (max-width: 2200px) {
  .contact-content {
    background-image: url(../../../public//images/contact-bck-xxl.jpg);
    background-size: contain;
    height: 2300px;
    padding-top: 150px;
  }
  .contact-content-inner {
    gap: 100px;
  }
  .contact-content-bottom {
    margin-bottom: 20%;
  }
  .contact-content-logo {
    width: 23%;
    margin-bottom: 18%;
  }
  .contact-content-heading h1 {
    font-size: 4.0625rem;
  }
  .contact-content-heading img {
    margin-top: 40px;
  }
  .footer-content {
    margin-bottom: -50px;
  }
}
@media screen and (max-width: 2000px) {
  .react-parallax-bgimage {
    position: absolute;
    right: -1500px;
    left: auto !important;
  }
  .contact-content {
    background-size: contain;
    height: 2150px;
    padding-top: 150px;
  }
  .contact-content-top {
    gap: 100px;
  }
  .contact-content-inner {
    gap: 90px;
  }
  .contact-content-bottom {
    margin-bottom: 20%;
  }
  .contact-content-logo {
    margin-bottom: 15%;
  }
  .contact-content-heading h1 {
    font-size: 4.0625rem;
  }
  .contact-content-heading img {
    margin-top: 40px;
  }
  .contact-content-jogi {
    margin-top: 12%;
  }
  .footer-content {
    margin-bottom: -50px;
  }
}
@media screen and (max-width: 1900px) {
  .contact-content {
    background-size: contain;
    height: 2000px;
    padding-top: 150px;
  }
  .contact-content-bottom {
    margin-bottom: 20%;
  }
  .contact-content-logo {
    width: 18%;
    margin-bottom: 13%;
  }
  .contact-content-form {
    width: 35%;
  }
  .footer-content {
    margin-bottom: -100px;
  }
}
@media screen and (max-width: 1780px) {
  .contact-content {
    background-image: url(../../../public//images/contact-bck-mobile-xl.jpg);
    background-size: contain;
    height: 2150px;
    padding-top: 150px;
  }
  .contact-content-inner {
    gap: 90px;
  }
  .contact-content-bottom {
    margin-bottom: 23%;
  }
  .contact-content-heading h1 {
    font-size: 4.0625rem;
  }
  .contact-content-heading img {
    margin-top: 40px;
  }
  .contact-content-logo {
    width: 22%;
    margin-bottom: 15%;
  }
  .contact-content-form {
    width: 35%;
  }
  .contact-content-jogi {
    margin-top: 17%;
  }
}
@media screen and (max-width: 1700px) {
  .contact-content {
    background-image: url(../../../public//images/contact-bck-mobile-xl.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    height: 2050px;
    padding-top: 150px;
  }
  .contact-content-top {
    gap: 50px;
  }
  .contact-content-inner {
    gap: 90px;
  }
  .contact-content-bottom {
    margin-bottom: 23%;
  }
  .contact-content-heading h1 {
    font-size: 4.0625rem;
  }
  .contact-content-heading img {
    margin-top: 40px;
  }
  .contact-content-logo {
    width: 22%;
    margin-bottom: 15%;
  }
  .contact-content-form {
    width: 35%;
  }
  .contact-content-jogi {
    margin-top: 13%;
  }
}
@media screen and (max-width: 1600px) {
  .hero {
    height: clamp(850px, calc(100vh + 170px), 2000px);
  }
  .hero-icon {
    height: 170px;
    bottom: -170px;
    left: calc(50% - (170px / 2));
  }
  .hero-icon-inactive {
    animation: anim_heroLogo 0.5s, rotation 50s infinite linear;
    animation-fill-mode: both;
    animation-delay: 0.4s;
  }
  @keyframes anim_heroLogo {
    0% {
      opacity: 0;
      bottom: -170px;
    }
    100% {
      opacity: 1;
      bottom: -85px;
    }
  }
  .hero-icon-cancel-init-anim {
    opacity: 1 !important;
    bottom: -85px !important;
  }
  .hero-slider {
    height: calc(100% - 170px);
  }
  .hero-slider .slider-stars {
    bottom: -100px !important;
  }
  .hero-slider-slide.slide-1 .container .button-container {
    padding-top: clamp(calc(850px * 0.1), 10vh, calc(2000px * 0.1));
  }
  .hero-slider-slide.slide-2 .slide-2-left-content {
    margin-top: clamp(calc(-850px * 0.18), calc(-100vh * 0.18), calc(-2000px * 0.18));
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-heading.first {
    font-size: 3.9375rem;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-heading.second {
    font-size: 3.5rem;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-heading.third {
    font-size: 2.625rem;
  }
  .hero-slider-slide.slide-3 .slide-3-left,
  .hero-slider-slide.slide-3 .slide-3-right {
    width: calc((100% - 1300px) / 2);
  }
  .hero-slider-slide.slide-3 .slide-3-content {
    max-width: 1300px;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-left {
    margin-left: -20%;
    min-height: 1000px;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-right-container {
    right: -7%;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-right-container-logo {
    width: 500px;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-right-container-image {
    margin-top: -300px;
  }
  .hero-banners-banner-left {
    left: 3%;
    height: 110px;
    width: 140px;
  }
  .hero-lang-switch {
    top: 35px;
  }
  .our-experience-content-container {
    padding: 0 50px;
    box-sizing: border-box;
  }
  .our-focus-content-heading {
    font-size: 3.125rem;
    margin-bottom: 90px;
  }
  .our-focus-content-logo {
    width: 400px;
  }
  .our-focus-content-suspensions {
    margin-top: 60px;
  }
  .our-focus-content-suspensions-item {
    height: 390px;
  }
  .service-content-top-right-image {
    width: 85%;
  }
  .service-content-squares {
    grid-template-columns: repeat(2, minmax(450px, 1fr));
  }
  .footer-content {
    width: 38%;
  }
}
@media screen and (max-width: 1560px) {
  .contact-content {
    background-image: url(../../../public//images/contact-bck-mobile-lg.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    height: 2175px;
    padding-top: 150px;
  }
  .contact-content-inner {
    gap: 100px;
  }
  .contact-content-bottom {
    margin-bottom: 25%;
  }
  .contact-content-heading h1 {
    font-size: 4.0625rem;
  }
  .contact-content-heading img {
    margin-top: 40px;
  }
  .contact-content-logo {
    width: 22%;
    margin-bottom: 15%;
  }
  .contact-content-form {
    width: 35%;
  }
  .contact-content-jogi {
    width: 43%;
  }
}
@media screen and (max-width: 1400px) {
  .hero {
    height: clamp(850px, calc(100vh + 160px), 2000px);
  }
  .hero-slider {
    height: calc(100% - 160px);
  }
  .hero-slider .slider-stars {
    bottom: -100px !important;
  }
  .hero-icon {
    height: 160px;
    bottom: -160px;
    left: calc(50% - (160px / 2));
  }
  .hero-icon-inactive {
    animation: anim_heroLogo 0.5s, rotation 50s infinite linear;
    animation-fill-mode: both;
    animation-delay: 0.4s;
  }
  @keyframes anim_heroLogo {
    0% {
      opacity: 0;
      bottom: -160px;
    }
    100% {
      opacity: 1;
      bottom: -80px;
    }
  }
  .hero-icon-cancel-init-anim {
    opacity: 1 !important;
    bottom: -80px !important;
  }
  .contact-content {
    background-image: url(../../../public//images/contact-bck-mobile-lg.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    height: 1950px;
    padding-top: 130px;
  }
  .contact-content-inner {
    gap: 80px;
  }
  .contact-content-bottom {
    margin-bottom: 23%;
  }
  .contact-content-heading h1 {
    font-size: 4.0625rem;
  }
  .contact-content-heading img {
    margin-top: 40px;
  }
  .contact-content-form {
    width: 50%;
  }
  .contact-content-logo {
    width: 25%;
    margin-bottom: 12%;
  }
  .contact-content-jogi {
    width: 43%;
    margin-top: 12%;
  }
  .service-content-top-right-image {
    width: 95%;
  }
  .footer-content {
    width: 53%;
    margin-bottom: -100px;
  }
}
@media screen and (max-width: 1320px) {
  .react-parallax-bgimage {
    position: absolute;
    right: -1600px;
    left: auto !important;
  }
}
@media screen and (max-width: 1290px) {
  .hero-slider-slide.slide-2 .slide-2-left-content {
    margin-top: clamp(calc(-900px * 0.1), calc(-100vh * 0.1), calc(-1200px * 0.1));
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-heading.first {
    font-size: 3.4375rem;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-heading.second {
    font-size: 3.0625rem;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-heading.third {
    font-size: 2.4375rem;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-bottom-container {
    margin-top: clamp(calc(900px * 0.05), calc(100vh * 0.05), calc(1200px * 0.05));
  }
  .hero-slider-slide.slide-2 .slide-2-right-content {
    margin-left: -10%;
  }
  .hero-slider-slide.slide-2.slide-3 .slide-3-left,
  .hero-slider-slide.slide-2.slide-3 .slide-3-right {
    width: calc((100% - 1300px) / 2);
  }
  .hero-slider-slide.slide-2.slide-3 .slide-3-content {
    max-width: 1300px;
  }
  .hero-slider-slide.slide-2.slide-3 .slide-3-content-image-left {
    margin-left: -10%;
    min-height: 1000px;
  }
  .hero-slider-slide.slide-2.slide-3 .slide-3-content-image-right-container {
    right: -7%;
  }
  .hero-slider-slide.slide-2.slide-3 .slide-3-content-image-right-container-logo {
    width: 500px;
  }
  .hero-slider-slide.slide-2.slide-3 .slide-3-content-image-right-container-image {
    margin-top: -300px;
  }
  .our-focus-content-suspensions-star {
    display: none;
  }
  .contact-content {
    background-image: url(../../../public//images/contact-bck-mobile-lg.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    height: 1800px;
    padding-top: 120px;
  }
  .contact-content-inner {
    gap: 70px;
  }
  .contact-content-bottom {
    margin-bottom: 23%;
  }
  .contact-content-heading h1 {
    font-size: 4.0625rem;
  }
  .contact-content-heading img {
    margin-top: 40px;
  }
  .contact-content-form {
    width: 60%;
  }
  .contact-content-logo {
    width: 25%;
    margin-bottom: 10%;
  }
  .contact-content-jogi {
    width: 43%;
    margin-top: 10%;
  }
}
@media screen and (max-width: 1200px) {
  .hero {
    height: clamp(850px, calc(100vh + 140px), 2000px);
  }
  .hero-slider {
    height: calc(100% - 140px);
  }
  .hero-slider-slide.slide-1 .container .headings h1 {
    font-size: 4.6875rem;
  }
  .hero-slider-slide.slide-1 .container .headings h2 {
    font-size: 2.1875rem;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-heading.first {
    font-size: 3.4375rem;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-heading.second {
    font-size: 3.0625rem;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-heading.third {
    font-size: 2.4375rem;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-bottom-container {
    margin-top: clamp(calc(850px * 0.05), calc(100vh * 0.05), calc(2000px * 0.05));
  }
  .hero-slider-slide.slide-2 .slide-2-right-content {
    margin-left: -20%;
  }
  .hero-slider-slide.slide-3 .slide-3-left,
  .hero-slider-slide.slide-3 .slide-3-right {
    width: calc((100% - 1100px) / 2);
  }
  .hero-slider-slide.slide-3 .slide-3-right {
    position: absolute;
    display: block;
    right: 0;
    top: 0;
  }
  .hero-slider-slide.slide-3 .slide-3-content {
    max-width: 1100px;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-left {
    margin-left: -35%;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-right-container {
    right: -20%;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-right-container-logo {
    width: 400px;
    margin-left: -10%;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-right-container-image {
    margin-top: -300px;
    margin-left: -20%;
  }
  .hero-slider .slider-stars {
    bottom: -90px !important;
  }
  .hero-icon {
    height: 140px;
    bottom: -140px;
    left: calc(50% - (140px / 2));
  }
  .hero-icon-inactive {
    animation: anim_heroLogo 0.5s, rotation 50s infinite linear;
    animation-fill-mode: both;
    animation-delay: 0.4s;
  }
  @keyframes anim_heroLogo {
    0% {
      opacity: 0;
      bottom: -140px;
    }
    100% {
      opacity: 1;
      bottom: -70px;
    }
  }
  .hero-icon-cancel-init-anim {
    opacity: 1 !important;
    bottom: -70px !important;
  }
  /*
  .loader-icon {
    @rotating-logo-height: 200px;
    // top: calc(@hero-slider-height - (@rotating-logo-height / 2));
    height: @rotating-logo-height;
    top: clamp(
      calc(@hero-height-min - (@rotating-logo-height / 2)),
      calc(100vh - (@rotating-logo-height / 2)),
      calc(@hero-height-max - (@rotating-logo-height / 2))
    );
    left: calc(50% - (@rotating-logo-height / 2));

    &.first {
      top: clamp(
        calc((@hero-height-min - (@rotating-logo-height / 2)) * 0.75),
        calc((100vh - (@rotating-logo-height / 2)) * 0.75),
        calc((@hero-height-max - (@rotating-logo-height / 2)) * 0.75)
      );
    }

    &.second {
      &.loading {
        top: calc((50vh - @rotating-logo-height / 2) * 1.25);
      }
    }

    &.loading {
      position: absolute;
      top: calc(50vh - @rotating-logo-height / 2);
      left: calc(50% - @rotating-logo-height / 2);
    }
  } */
  .our-goal-content-heading {
    font-size: 4.0625rem;
  }
  .our-goal-content-paragraph {
    margin-top: 40px;
    font-size: 2rem;
  }
  .our-goal-content-stars-golden.second {
    margin: 100px 0;
  }
  .our-experience-content {
    padding: 200px 0;
  }
  .our-experience-content-paragraph {
    width: 65%;
  }
  .react-parallax-bgimage {
    position: absolute;
    right: -1400px;
    left: auto !important;
  }
  .our-focus-content-suspensions-item {
    flex: 0 0 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .service-content-top {
    flex-direction: column;
    /*
    &-right {
      //  max-height: 500px;
      width: 100%;
      height: 600px;
      // display: flex;
      justify-content: flex-end;

      &-image {
        position: relative;
        width: 80%;
        margin-top: 50px;

        &-background {
          display: none;
        }
        &-background.rotated {
          position: absolute;
          display: block;
          top: -45%;
          right: -30%;
          left: auto;
          height: 300%;
          overflow: hidden;
        }
      }
    } */
  }
  .service-content-top-left {
    width: 100%;
    height: auto;
    padding: 100px 50px 0;
    box-sizing: border-box;
  }
  .service-content-top-right {
    width: 100%;
    height: 550px;
  }
  .service-content-top-right-image {
    right: -5%;
    top: 17%;
    width: 60%;
  }
  .service-content-top-right-image-bck {
    width: 35%;
    inset: -13% 0 0 25%;
  }
  .service-content-top-right-image-bck-blust.normal {
    display: none;
  }
  .service-content-top-right-image-bck-blust.rotated {
    display: block;
  }
  .service-content-top-right-image-bck.normal {
    display: none;
  }
  .service-content-top-right-image-bck.rotated {
    display: block;
  }
  .service-content-top-right-bcks {
    overflow: visible;
  }
  .contact-content {
    background-image: url(../../../public//images/contact-bck-mobile-lg.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    height: 1700px;
    padding-top: 100px;
  }
  .contact-content-inner {
    gap: 50px;
  }
  .contact-content-bottom {
    margin-bottom: 26%;
  }
  .contact-content-heading h1 {
    font-size: 4.0625rem;
  }
  .contact-content-heading img {
    margin-top: 40px;
  }
  .contact-content-form {
    width: 60%;
  }
  .contact-content-logo {
    width: 25%;
    margin-bottom: 11%;
  }
  .contact-content-jogi {
    width: 40%;
    margin-top: 12%;
  }
  .footer-content {
    width: 63%;
  }
}
@media screen and (max-width: 1075px) {
  .hero-slider-slide.slide-3 .slide-3-left,
  .hero-slider-slide.slide-3 .slide-3-right {
    width: calc((100% - 1100px) / 2);
  }
  .hero-slider-slide.slide-3 .slide-3-right {
    position: absolute;
    display: block;
    right: 0;
    top: 0;
  }
  .hero-slider-slide.slide-3 .slide-3-content {
    max-width: 1100px;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-left {
    margin-left: -38%;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-right-container {
    right: -25%;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-right-container-logo {
    width: 400px;
    margin-left: -10%;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-right-container-image {
    margin-top: -350px;
    margin-left: -15%;
  }
  .contact-content {
    background-image: url(../../../public//images/contact-bck-mobile-lg.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    height: 1530px;
    padding-top: 100px;
  }
  .contact-content-inner {
    gap: 50px;
  }
  .contact-content-bottom {
    margin-bottom: 28%;
  }
  .contact-content-heading h1 {
    font-size: 4.0625rem;
  }
  .contact-content-heading img {
    margin-top: 40px;
  }
  .contact-content-form {
    width: 70%;
  }
  .contact-content-logo {
    width: 23%;
    margin-bottom: 7%;
  }
  .contact-content-jogi {
    width: 45%;
    margin-top: 5%;
  }
}
@media screen and (max-width: 992px) {
  .react-parallax-bgimage {
    position: absolute;
    right: -1250px;
    left: auto !important;
  }
  .hero {
    height: clamp(850px, calc(100vh + 150px), 2000px);
  }
  .hero-slider {
    height: calc(100% - 150px);
  }
  .hero-slider-slide.slide-1 .container .headings h1 {
    font-size: 4.375rem;
    letter-spacing: -0.21875rem;
  }
  .hero-slider-slide.slide-1 .container .headings h2 {
    font-size: 2rem;
  }
  .hero-slider-slide.slide-1 .container .button-container {
    padding-top: clamp(calc(850px * 0.125), 12.5vh, calc(2000px * 0.125));
  }
  .hero-slider-slide.slide-2 .slide-2-left {
    width: 55%;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-heading.first {
    font-size: 3.125rem;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-heading.second {
    font-size: 2.625rem;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-heading.third {
    font-size: 2.1875rem;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-stars-white {
    max-width: 212px;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-bottom-container {
    margin-top: clamp(calc(850px * 0.03), calc(100vh * 0.03), calc(2000px * 0.03));
    flex-direction: column-reverse;
    align-items: flex-start;
    row-gap: clamp(calc(850px * 0.03), calc(100vh * 0.03), calc(2000px * 0.03));
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-bottom-container-stars-green {
    max-width: 212px;
    margin-left: 7.5%;
  }
  .hero-slider-slide.slide-2 .slide-2-right {
    width: 35%;
  }
  .hero-slider-slide.slide-2 .slide-2-right-content-image {
    padding: 12.5% 0 12.5% 0;
    margin-left: -3%;
    max-height: 75%;
  }
  .hero-slider-slide.slide-3 .slide-3-left,
  .hero-slider-slide.slide-3 .slide-3-right {
    width: calc((100% - 800px) / 2);
  }
  .hero-slider-slide.slide-3 .slide-3-right {
    position: absolute;
    display: block;
    right: 0;
    top: 0;
  }
  .hero-slider-slide.slide-3 .slide-3-content {
    max-width: 800px;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-left {
    margin-left: -50%;
    min-height: 900px;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-right-container {
    right: -40%;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-right-container-logo {
    width: 400px;
    margin-left: 0;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-right-container-image {
    margin-top: -350px;
    margin-left: -20%;
  }
  .hero-slider .slider-stars {
    bottom: -85px !important;
  }
  .hero-button {
    padding: 16px 36px;
  }
  .hero-icon {
    height: 150px;
    bottom: -150px;
    left: calc(50% - (150px / 2));
  }
  .hero-icon-inactive {
    animation: anim_heroLogo 0.5s, rotation 50s infinite linear;
    animation-fill-mode: both;
    animation-delay: 0.4s;
  }
  @keyframes anim_heroLogo {
    0% {
      opacity: 0;
      bottom: -150px;
    }
    100% {
      opacity: 1;
      bottom: -75px;
    }
  }
  /*
  .loader-icon {
    @rotating-logo-height: 180px;
    height: @rotating-logo-height;
    top: clamp(
      calc(@hero-height-min - (@rotating-logo-height / 2)),
      calc(100vh - (@rotating-logo-height / 2)),
      calc(@hero-height-max - (@rotating-logo-height / 2))
    );
    left: calc(50% - (@rotating-logo-height / 2));

    &.first {
      top: clamp(
        calc((@hero-height-min - (@rotating-logo-height / 2)) * 0.75),
        calc((100vh - (@rotating-logo-height / 2)) * 0.75),
        calc((@hero-height-max - (@rotating-logo-height / 2)) * 0.75)
      );
    }

    &.second {
      &.loading {
        top: calc((50vh - @rotating-logo-height / 2) * 1.25);
      }
    }

    &.loading {
      position: absolute;
      top: calc(50vh - @rotating-logo-height / 2);
      left: calc(50% - @rotating-logo-height / 2);
    }
  } */
  .our-goal-content-heading {
    margin-top: 160px;
    font-size: 3.75rem;
  }
  .our-goal-content-paragraph {
    margin-top: 30px;
    font-size: 1.875rem;
    max-width: 80%;
  }
  .our-goal-content-stars-golden {
    width: 190px;
  }
  .our-goal-content-stars-golden.first {
    margin-top: 80px;
  }
  .our-goal-content-stars-golden.second {
    margin: 90px 0;
  }
  .our-experience-content {
    padding: 160px 0;
  }
  .our-experience-content-paragraph {
    width: 70%;
    font-size: 1.875rem;
  }
  .our-experience-content-paragraph-title {
    font-size: 3.625rem;
  }
  .our-focus-content-heading {
    font-size: 2.8125rem;
    margin: 80px 15px;
  }
  .our-focus-content-logo {
    width: 380px;
  }
  .our-focus-content-suspensions {
    margin-top: 50px;
    justify-content: space-evenly;
  }
  .our-focus-content-suspensions-item {
    flex: 0 0 50%;
    height: 350px;
  }
  .service-content-top-right {
    margin-top: 0;
  }
  .service-content-top-right-image {
    top: 18%;
    right: -6%;
    width: 65%;
  }
  .service-content-top-right-image-bck {
    width: 35%;
    inset: -10% 0 0 23%;
  }
  .service-content-top-right-image-bck-blust {
    display: none;
  }
  .service-content-squares {
    grid-template-columns: repeat(1, minmax(450px, 1fr));
  }
  .service-content-squares .square-container .heading {
    left: -1%;
  }
  .footer-content {
    width: 73%;
    margin-bottom: -100px;
  }
}
@media screen and (max-width: 965px) {
  .contact-content {
    background-image: url(../../../public//images/contact-bck-mobile-md.jpg);
    height: 1970px;
    padding-top: 170px;
  }
  .contact-content-inner {
    gap: 90px;
  }
  .contact-content-bottom {
    margin-bottom: 38%;
  }
  .contact-content-heading h1 {
    font-size: 4.0625rem;
  }
  .contact-content-heading img {
    margin-top: 40px;
  }
  .contact-content-form {
    width: 70%;
  }
  .contact-content-logo {
    width: 33%;
    margin-bottom: 17%;
  }
  .contact-content-jogi {
    width: 65%;
    margin-top: 13%;
  }
}
@media screen and (max-width: 860px) {
  .service-content-top-right-image {
    top: 18%;
    right: -6%;
    width: 75%;
  }
  .contact-content {
    height: 1780px;
    padding-top: 150px;
  }
  .contact-content-inner {
    gap: 70px;
  }
  .contact-content-bottom {
    margin-bottom: 41%;
  }
  .contact-content-heading h1 {
    font-size: 4.0625rem;
  }
  .contact-content-heading img {
    margin-top: 40px;
  }
  .contact-content-form {
    width: 70%;
  }
  .contact-content-logo {
    width: 33%;
    margin-bottom: 18%;
  }
  .contact-content-jogi {
    width: 60%;
    margin-top: 15%;
  }
}
@media screen and (max-width: 768px) {
  .react-parallax-bgimage {
    position: absolute;
    right: -1150px;
    left: auto !important;
  }
  .hero {
    height: clamp(850px, calc(100vh + 140px), 2000px);
  }
  .hero-lang-switch {
    top: 25px;
    right: 15%;
  }
  .hero-slider {
    height: calc(100% - 140px);
  }
  .hero-slider-slide.slide-1 .container .headings {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .hero-slider-slide.slide-1 .container .headings h1 {
    font-size: 4.0625rem;
  }
  .hero-slider-slide.slide-1 .container .headings h2 {
    font-size: 2rem;
  }
  .hero-slider-slide.slide-1 .container .button-container {
    padding-top: clamp(calc(850px * 0.125), calc(100vh * 0.125), calc(2000px * 0.125));
  }
  .hero-slider-slide.slide-2 .slide-2-left {
    width: 100%;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-heading.first {
    font-size: 3.75rem;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-heading.second {
    font-size: 3.25rem;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-heading.third {
    font-size: 2.625rem;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-bottom-container {
    align-items: center;
    flex-direction: row;
  }
  .hero-slider-slide.slide-2 .slide-2-right {
    display: none;
    width: 0%;
  }
  .hero-slider-slide.slide-3 .slide-3-content {
    max-width: 800px;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-left {
    margin-left: -50%;
    min-height: 800px;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-right-container {
    right: 0;
    justify-content: flex-end;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-right-container-logo {
    width: 350px;
    margin-left: 0;
    margin-right: 40px;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-right-container-image {
    display: none;
    margin-top: -350px;
    margin-left: -20%;
  }
  .hero-slider-slide .slider-stars {
    bottom: -80px !important;
  }
  .hero-banners-banner-left {
    left: 3%;
    height: 90px;
    width: 110px;
  }
  .hero-banners-banner-right {
    padding: 15px;
    row-gap: 30px;
  }
  .hero-banners-banner-right-icon img {
    height: 18px;
  }
  .hero-banners-banner-right-lang-s {
    right: -1px;
  }
  .hero-icon {
    height: 140px;
    bottom: -140px;
    left: calc(50% - (140px / 2));
  }
  .hero-icon-inactive {
    animation: anim_heroLogo 0.5s, rotation 50s infinite linear;
    animation-fill-mode: both;
    animation-delay: 0.4s;
  }
  @keyframes anim_heroLogo {
    0% {
      opacity: 0;
      bottom: -140px;
    }
    100% {
      opacity: 1;
      bottom: -70px;
    }
  }
  .hero-icon-cancel-init-anim {
    opacity: 1 !important;
    bottom: -70px !important;
  }
  /*
  .loader-icon {
    @rotating-logo-height: 160px;
    height: @rotating-logo-height;
    top: clamp(
      calc(@hero-height-min - (@rotating-logo-height / 2)),
      calc(100vh - (@rotating-logo-height / 2)),
      calc(@hero-height-max - (@rotating-logo-height / 2))
    );
    left: calc(50% - (@rotating-logo-height / 2));

    &.first {
      top: clamp(
        calc((@hero-height-min - (@rotating-logo-height / 2)) * 0.75),
        calc((100vh - (@rotating-logo-height / 2)) * 0.75),
        calc((@hero-height-max - (@rotating-logo-height / 2)) * 0.75)
      );
    }

    &.second {
      &.loading {
        top: calc((50vh - @rotating-logo-height / 2) * 1.25);
      }
    }

    &.loading {
      position: absolute;
      top: calc(50vh - @rotating-logo-height / 2);
      left: calc(50% - @rotating-logo-height / 2);
    }
  } */
  .our-goal-content-heading {
    margin-top: 160px;
    font-size: 3.75rem;
  }
  .our-goal-content-paragraph {
    margin-top: 30px;
    font-size: 1.875rem;
    max-width: 80%;
  }
  .our-goal-content-stars-golden {
    width: 190px;
  }
  .our-goal-content-stars-golden.first {
    margin-top: 80px;
  }
  .our-goal-content-stars-golden.second {
    margin: 90px 0;
  }
  .our-experience-content {
    padding: 130px 0;
  }
  .our-experience-content-paragraph {
    width: 90%;
  }
  .our-focus-content-heading {
    margin: 80px 15px 60px;
  }
  .our-focus-content-logo {
    width: 350px;
  }
  .our-focus-content-suspensions {
    margin: 35px 15px 50px;
  }
  .our-focus-content-suspensions-item {
    height: 300px;
  }
  .our-focus-content-paragraph {
    margin: 0 15px 70px;
    padding: 0 50px;
    box-sizing: border-box;
  }
  .service-content-top-left {
    padding: 80px 50px 20px;
    width: 100%;
    box-sizing: border-box;
  }
  .service-content-top-left-heading {
    padding-left: 0px;
    text-align: center;
  }
  .service-content-top-left-paragraph {
    padding: 0px;
    text-align: center;
  }
  .service-content-top-right {
    height: 450px;
    margin-top: 0;
  }
  .service-content-top-right-image {
    width: auto;
    height: 500px;
    right: -7%;
    top: 18%;
  }
  .service-content-top-right-image-bck {
    width: 50%;
    inset: -20% 0 0 15%;
  }
  .service-content-top-right-image-bck-blust {
    display: none;
  }
  .service-content-squares {
    grid-template-columns: minmax(400px, 1fr);
    grid-auto-rows: minmax(380px, 1fr);
  }
  .service-content-squares .square-container .square-content .square-icon {
    height: 90px;
    width: 90px;
  }
  .service-content-squares .square-container .square-content .square-icon.paint {
    width: 65.7px;
  }
  .service-content-squares .square-container .square-content .square-text-container {
    margin-top: 200px;
  }
  .contact-content {
    height: 1700px;
    padding-top: 100px;
  }
  .contact-content-inner {
    gap: 70px;
  }
  .contact-content-bottom {
    margin-bottom: 47%;
  }
  .contact-content-heading h1 {
    font-size: 4.0625rem;
  }
  .contact-content-heading img {
    margin-top: 50px;
  }
  .contact-content-form {
    width: 80%;
  }
  .contact-content-logo {
    width: 35%;
    margin-bottom: 18%;
  }
  .contact-content-jogi {
    width: 60%;
    margin-top: 12%;
  }
  .footer-content {
    width: 83%;
  }
}
@media screen and (max-width: 730px) {
  .contact-content-bottom {
    margin-bottom: 55%;
  }
  .contact-content-form {
    width: 80%;
  }
  .contact-content-logo {
    width: 35%;
    margin-bottom: 18%;
  }
}
@media screen and (max-width: 670px) {
  .react-parallax-bgimage {
    position: absolute;
    right: -1300px;
    left: auto !important;
  }
  .contact-content {
    height: 1530px;
    padding-top: 50px;
  }
  .contact-content-inner {
    gap: 40px;
  }
  .contact-content-bottom {
    margin-bottom: 43%;
  }
  .contact-content-heading img {
    margin-top: 30px;
  }
  .contact-content-form {
    width: 87%;
    gap: 20px;
  }
  .contact-content-form > div:first-child {
    flex-direction: column;
    gap: 20px;
  }
  .contact-content-form div:last-child {
    flex-direction: column;
    gap: 20px;
  }
  .contact-content-form div:last-child > div {
    order: 2;
  }
  .contact-content-form div:last-child button {
    order: 1;
    width: 100%;
  }
  .contact-content-logo {
    width: 30%;
    margin-bottom: 10%;
  }
  .contact-content-jogi {
    width: 70%;
    margin-top: 5%;
  }
}
@media screen and (max-width: 635px) {
  .hero-slider-slide.slide-2 .slide-2-left-content-heading.first {
    font-size: 3.25rem;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-heading.second {
    font-size: 2.8125rem;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-heading.third {
    font-size: 2.375rem;
  }
  .hero-slider-slide.slide-2 .slide-2-right {
    display: none;
    width: 0%;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-left {
    margin-left: -60%;
  }
  .our-goal-content-heading {
    margin-top: 160px;
    font-size: 3.75rem;
  }
  .our-goal-content-paragraph {
    margin-top: 30px;
    font-size: 1.875rem;
    max-width: 80%;
  }
  .our-goal-content-stars-golden {
    width: 190px;
  }
  .our-goal-content-stars-golden.first {
    margin-top: 80px;
  }
  .our-goal-content-stars-golden.second {
    margin: 90px 0;
  }
  .service-content-top-left {
    padding: 80px 30px 20px;
  }
  /*
  .contact-content-footer {
    &-logo {
      margin: 170px auto;
    }

    &-info {
      flex-direction: column;

      &-left,
      &-right {
        text-align: center;
        width: 100%;
      }

      &-star {
        height: 50px;
        width: 50px;
      }
    }
  } */
  .our-focus-content-heading {
    margin: 70px 0px 50px;
    padding: 0 15px;
    box-sizing: border-box;
    font-size: 2.6875rem;
  }
  .our-focus-content-logo {
    width: 300px;
  }
  .our-focus-content-suspensions {
    margin-top: 10px;
  }
  .our-focus-content-paragraph {
    padding: 0 40px;
  }
  .contact-content {
    height: 1400px;
    padding-top: 70px;
  }
  .contact-content-inner {
    gap: 40px;
  }
  .contact-content-bottom {
    margin-bottom: 45%;
  }
  .contact-content-heading img {
    margin-top: 30px;
  }
  .contact-content-form {
    width: 87%;
    gap: 20px;
  }
  .contact-content-form > div:first-child {
    flex-direction: column;
    gap: 20px;
  }
  .contact-content-form div:last-child {
    flex-direction: column;
    gap: 20px;
  }
  .contact-content-form div:last-child > div {
    order: 2;
  }
  .contact-content-form div:last-child button {
    order: 1;
    width: 100%;
  }
  .contact-content-logo {
    display: none;
    width: 35%;
  }
  .contact-content-jogi {
    width: 70%;
  }
}
@media screen and (max-width: 576px) {
  .react-parallax-bgimage {
    position: absolute;
    right: -1250px;
    left: auto !important;
  }
  .hero {
    height: clamp(850px, calc(100vh + 130px), 2000px);
  }
  .hero-slider {
    height: calc(100% - 130px);
  }
  .hero-slider .slider-stars {
    bottom: -75px !important;
  }
  .hero-slider-slide.slide-1 .transparent {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
  }
  .hero-slider-slide.slide-1 .container .headings {
    max-width: none;
    margin-top: 0;
  }
  .hero-slider-slide.slide-1 .container .headings h1 {
    letter-spacing: -0.125rem;
    max-width: 95%;
    font-size: 4.0625rem;
  }
  .hero-slider-slide.slide-1 .container .headings h2 {
    max-width: 80%;
    font-size: 2rem;
    margin: 0 auto;
  }
  .hero-slider-slide.slide-1 .container .button-container {
    padding-top: clamp(calc(850px * 0.1), calc(100vh * 0.1), calc(2000px * 0.1));
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-heading {
    padding: 0 38px;
  }
  .hero-slider-slide.slide-2 .slide-2-stars {
    width: 150px;
    left: calc(50% - 75px);
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-left {
    margin-left: -80%;
    min-height: 900px;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-right-container {
    right: 0;
    justify-content: flex-end;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-right-container-logo {
    width: 320px;
    margin-right: 20px;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-right-container-image {
    display: none;
    margin-top: -350px;
    margin-left: -20%;
  }
  .hero-slider-slide.slide-3 .slide-3-stars {
    width: 150px;
    left: calc(50% - 75px);
  }
  .hero-banners-banner-left {
    left: 3%;
    height: 90px;
    width: 110px;
  }
  .hero-button {
    padding: 14px 32px;
  }
  .hero-icon {
    height: 130px;
    bottom: -130px;
    left: calc(50% - (130px / 2));
  }
  .hero-icon-inactive {
    animation: anim_heroLogo 0.5s, rotation 50s infinite linear;
    animation-fill-mode: both;
    animation-delay: 0.4s;
  }
  @keyframes anim_heroLogo {
    0% {
      opacity: 0;
      bottom: -130px;
    }
    100% {
      opacity: 1;
      bottom: -65px;
    }
  }
  .hero-icon-cancel-init-anim {
    opacity: 1 !important;
    bottom: -65px !important;
  }
  /*
  .loader-icon {
    @rotating-logo-height: 140px;
    height: @rotating-logo-height;
    top: clamp(
      calc(@hero-height-min - (@rotating-logo-height / 2)),
      calc(100vh - (@rotating-logo-height / 2)),
      calc(@hero-height-max - (@rotating-logo-height / 2))
    );
    left: calc(50% - (@rotating-logo-height / 2));

    &.first {
      top: clamp(
        calc((@hero-height-min - (@rotating-logo-height / 2)) * 0.75),
        calc((100vh - (@rotating-logo-height / 2)) * 0.75),
        calc((@hero-height-max - (@rotating-logo-height / 2)) * 0.75)
      );
    }

    &.second {
      &.loading {
        top: calc((50vh - @rotating-logo-height / 2) * 1.25);
      }
    }

    &.loading {
      position: absolute;
      top: calc(50vh - @rotating-logo-height / 2);
      left: calc(50% - @rotating-logo-height / 2);
    }
  } */
  .our-goal-content-heading {
    margin: 130px 0 0;
    text-align: center;
  }
  .our-goal-content .stars {
    width: 160px;
  }
  .our-goal-content .stars.first {
    margin: 65px 0;
  }
  .our-goal-content .stars.second {
    margin: 65px 0;
  }
  .our-goal-content-heading {
    font-size: 3.625rem;
  }
  .our-goal-content-paragraph {
    margin-top: 20px;
    max-width: 90%;
    font-size: 1.75rem;
  }
  .our-experience-content {
    padding: 110px 0;
  }
  .our-experience-content-paragraph {
    width: 100%;
    margin: 0;
  }
  .our-experience-content-paragraph-title {
    margin: 0;
    padding-bottom: 15px;
    line-height: 1;
  }
  .our-focus-content-heading {
    margin: 60px 0 40px;
  }
  .our-focus-content-suspensions-item {
    height: 260px;
  }
  .our-focus-content-paragraph {
    margin-bottom: 60px;
  }
  .service-content-top-left {
    padding: 60px 30px 20px;
  }
  .service-content-top-right {
    height: 400px;
    margin-top: 0px;
  }
  .service-content-top-right-image {
    height: 450px;
    top: 15%;
  }
  .service-content-top-right-image-bck {
    inset: -18% 0 0 8%;
    width: 60%;
    height: auto;
  }
  .service-content-top-right-image-bck-blust {
    width: 60%;
    inset: 10% 0 0 10%;
    height: auto;
  }
  .service-content-top-bcks {
    width: 100%;
    height: 100%;
  }
  /*
  .contact-content-form-top {
    flex-direction: column;
    row-gap: 25px;

    & .input.name {
      width: 100%;
    }
    & .input.email-phone {
      width: 100%;
    }
  } */
  .service-content-squares {
    padding: 10px 10px;
    grid-template-columns: minmax(100%, 1fr);
    grid-auto-rows: minmax(300px, 1fr);
    row-gap: 10px;
  }
  .service-content-squares .square-container .square-content {
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    width: 80%;
    padding: 0 10% 0 10%;
  }
  .service-content-squares .square-container .square-content .square-icon {
    padding-top: 0px;
    height: 90px;
    width: 90px;
  }
  .service-content-squares .square-container .square-content .square-text-container {
    margin-left: 0;
    padding-bottom: 20px;
  }
  .service-content-squares .square-container .square-content .square-paragraph {
    display: none;
  }
  .service-content-squares .square-container .square-content .square-star {
    top: 20px;
    left: -35px;
    height: 250px;
    width: 250px;
  }
  .service-content-squares .square-container .heading {
    bottom: -4.5%;
  }
  .contact {
    /*
    .footer {
      &-content {
        width: 92%;
      }
    } */
  }
  .contact-content {
    height: 1300px;
    padding-top: 50px;
  }
  .contact-content-inner {
    gap: 40px;
  }
  .contact-content-bottom {
    margin-bottom: 47%;
  }
  .contact-content-heading img {
    margin-top: 30px;
  }
  .contact-content-form {
    width: 87%;
    gap: 20px;
  }
  .contact-content-form > div:first-child {
    flex-direction: column;
    gap: 20px;
  }
  .contact-content-form div:last-child {
    flex-direction: column;
    gap: 20px;
  }
  .contact-content-form div:last-child > div {
    order: 2;
  }
  .contact-content-form div:last-child button {
    order: 1;
    width: 100%;
  }
  .contact-content-form .notifications .content .message {
    font-size: 13px;
  }
  .contact-content-logo {
    display: none;
    width: 47%;
  }
  .contact-content-jogi {
    width: 70%;
    margin-top: 20%;
  }
  .footer-content h1 {
    font-size: 2.1875rem;
  }
}
@media screen and (max-width: 525px) {
  .contact-content {
    height: 1200px;
    padding-top: 50px;
  }
  .contact-content-bottom {
    margin-bottom: 52%;
  }
  .contact-content-jogi {
    width: 70%;
    margin-top: 8%;
  }
}
@media screen and (max-width: 480px) {
  .react-parallax-bgimage {
    position: absolute;
    right: -1050px;
    left: auto !important;
  }
  .hero {
    height: clamp(850px, calc(100vh + 120px), 2000px);
  }
  .hero-lang-switch {
    top: 22.5px;
    right: 15%;
  }
  .hero-slider {
    height: calc(100% - 120px);
  }
  .hero-slider .slider-stars {
    bottom: -70px !important;
  }
  .hero-slider-slide.slide-1 .container .headings {
    max-width: none;
    margin-top: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
  .hero-slider-slide.slide-1 .container .headings h1 {
    letter-spacing: -0.125rem;
    max-width: 100%;
    font-size: 3.4375rem;
  }
  .hero-slider-slide.slide-1 .container .headings h2 {
    max-width: 80%;
    font-size: 1.75rem;
    margin: 0 auto;
  }
  .hero-slider-slide.slide-1 .container .button-container {
    padding: 14px 32px;
    margin-top: clamp(calc(850px * 0.06), calc(100vh * 0.06), calc(2000px * 0.06));
  }
  .hero-slider-slide.slide-2 .slide-2-left-content {
    margin-top: clamp(calc(-850px * 0.1), calc(-100vh * 0.1), calc(-2000px * 0.1));
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-heading.first {
    font-size: 2.8125rem;
    padding-right: 24px;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-heading.second {
    font-size: 2.3125rem;
    padding-right: 24px;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-heading.third {
    font-size: 2rem;
    padding-right: 24px;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-bottom-container {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-bottom-container-stars-green {
    width: 156px;
  }
  .hero-slider-slide.slide-2 .slide-2-left-content-stars-white {
    width: 156px;
    padding: 25px 0 25px 7.5%;
  }
  .hero-slider-slide.slide-2 .slide-2-right {
    display: none;
    width: 0%;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-left {
    margin-left: -95%;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-right-container {
    right: 0;
    justify-content: flex-end;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-right-container-logo {
    width: 300px;
    margin-right: 20px;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-right-container-image {
    display: none;
    margin-top: -350px;
    margin-left: -20%;
  }
  .hero-banners-banner-left {
    left: 3%;
    height: 85px;
    width: 95px;
  }
  .hero-banners-banner-right {
    padding: 13px;
  }
  .hero-banners-banner-right-lang-s {
    top: 180px;
    right: -3px;
  }
  .hero .slick-dots {
    top: clamp(calc(650px * 0.8), calc(100vh * 0.8), calc(750px * 0.8));
  }
  .hero-icon {
    height: 120px;
    bottom: -120px;
    left: calc(50% - (120px / 2));
  }
  .hero-icon-inactive {
    animation: anim_heroLogo 0.5s, rotation 50s infinite linear;
    animation-fill-mode: both;
    animation-delay: 0.4s;
  }
  @keyframes anim_heroLogo {
    0% {
      opacity: 0;
      bottom: -120px;
    }
    100% {
      opacity: 1;
      bottom: -60px;
    }
  }
  /*
  .loader-icon {
    @rotating-logo-height: 130px;
    height: @rotating-logo-height;
    top: clamp(
      calc(@xs-hero-height-min - (@rotating-logo-height / 2)),
      calc(100vh - (@rotating-logo-height / 2)),
      calc(@xs-hero-height-max - (@rotating-logo-height / 2))
    );
    left: calc(50% - (@rotating-logo-height / 2));

    &.first {
      top: clamp(
        calc((@hero-height-min - (@rotating-logo-height / 2)) * 0.75),
        calc((100vh - (@rotating-logo-height / 2)) * 0.75),
        calc((@hero-height-max - (@rotating-logo-height / 2)) * 0.75)
      );
    }

    &.second {
      &.loading {
        top: calc((50vh - @rotating-logo-height / 2) * 1.25);
      }
    }

    &.loading {
      position: absolute;
      top: calc(50vh - @rotating-logo-height / 2);
      left: calc(50% - @rotating-logo-height / 2);
    }
  } */
  .our-goal-content-heading {
    margin: 120px 10px 0;
    text-align: center;
  }
  .our-goal-content .stars.first,
  .our-goal-content .stars.second {
    margin: 60px 0;
  }
  .our-goal-content-heading {
    font-size: 3.4375rem;
  }
  .our-goal-content-paragraph {
    margin-top: 15px;
    font-size: 1.625rem;
  }
  .our-experience-content {
    padding: 70px 0;
  }
  .our-experience-content-container {
    padding: 0 30px;
  }
  .our-experience-content-paragraph {
    font-size: 1.75rem;
  }
  .our-experience-content-paragraph-title {
    font-size: 3.25rem;
  }
  .our-focus-content-heading {
    margin: 60px 0 30px;
    font-size: 2.25rem;
    padding: 0 10px;
  }
  .our-focus-content-suspensions {
    margin-bottom: 30px;
  }
  .our-focus-content-suspensions-item {
    height: 220px;
  }
  .our-focus-content-paragraph {
    padding: 0 10px;
  }
  .our-focus-content-logo {
    width: 250px;
    max-width: 90%;
  }
  .service-content-top-left {
    padding: 50px 15px 10px;
  }
  .service-content-top-right {
    height: 400px;
    margin-top: 0px;
  }
  .service-content-top-right-image {
    height: 450px;
    top: 17%;
  }
  .service-content-top-right-image-bck {
    inset: -13% 0 0 8%;
    width: 60%;
    height: auto;
  }
  .service-content-top-right-image-bck-blust {
    width: 60%;
    inset: 10% 0 0 10%;
    height: auto;
  }
  .service-content-top-bcks {
    width: 100%;
    height: 100%;
  }
  .service-content-squares {
    padding: 5px 5px;
    row-gap: 5px;
    grid-auto-rows: minmax(250px, 1fr);
  }
  .service-content-squares .square-container .square-content {
    width: 90%;
    padding: 0 0 0 10%;
  }
  .service-content-squares .square-container .square-content .square-text-container {
    margin-top: 130px;
  }
  .service-content-squares .square-container .square-content .square-heading {
    font-size: 3.75rem;
  }
  .service-content-squares .square-container .square-content .square-icon {
    height: 70px;
    width: 70px;
  }
  .service-content-squares .square-container .square-content .square-icon.paint {
    width: 51.1px;
  }
  .service-content-squares .square-container .heading {
    bottom: -5%;
    left: -1.5%;
    font-size: 5rem;
    line-height: 5rem;
  }
  .contact-content {
    background-image: url(../../../public//images/contact-bck-mobile-xs.jpg);
    height: 1230px;
    padding-top: 80px;
  }
  .contact-content-inner {
    gap: 40px;
  }
  .contact-content-bottom {
    margin-bottom: 65%;
  }
  .contact-content-heading h1 {
    font-size: 3.4375rem;
  }
  .contact-content-heading img {
    margin-top: 30px;
  }
  .contact-content-form {
    padding: 20px;
    gap: 15px;
  }
  .contact-content-form > div:first-child {
    display: flex;
    gap: 15px;
  }
  .contact-content-form input,
  .contact-content-form button {
    height: 35px;
  }
  .contact-content-form textarea {
    min-width: 100px;
  }
  .contact-content-form .notifications {
    height: 35px;
  }
  .contact-content-form .notifications .content {
    height: 35px;
  }
  .contact-content-form .notifications .content .message {
    font-size: 13px;
  }
  .contact-content-logo {
    display: none;
  }
  .contact-content-jogi {
    width: 70%;
  }
  .footer-content {
    margin-bottom: -160px;
    gap: 30px;
  }
  .footer-content .content {
    flex-direction: column;
  }
  .footer-content .icons .links-icon {
    gap: 10px;
  }
  .footer-content .icons .links-icon svg {
    width: 16px;
    height: 16px;
  }
  .footer-content .icons .links-icon img {
    width: 7px;
    height: 7px;
  }
  .footer-content div {
    text-align: center !important;
  }
}
@media screen and (max-width: 440px) {
  .contact-content {
    height: 1115px;
    padding-top: 70px;
  }
  .contact-content-inner {
    gap: 30px;
  }
  .contact-content-bottom {
    margin-bottom: 63%;
  }
  .contact-content-heading img {
    margin-top: 18px;
  }
  .contact-content-form {
    padding: 20px;
    gap: 15px;
  }
  .contact-content-jogi {
    width: 65%;
  }
}
@media screen and (max-width: 405px) {
  .hero-slider-slide.slide-3 .slide-3-content-image-left {
    min-height: 800px;
    margin-left: -97%;
  }
  .hero-slider-slide.slide-3 .slide-3-content-image-right-container-logo {
    width: 270px;
  }
  .our-experience-content-container {
    padding: 0 20px;
  }
  .service-content-squares {
    padding: 5px 0px;
    row-gap: 5px;
    grid-auto-rows: minmax(250px, 1fr);
  }
  .service-content-squares .square-container .square-content {
    width: 76%;
    padding: 0 12% 0 12%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .service-content-squares .square-container .square-content .square-icon {
    height: 60px;
    width: 60px;
  }
  .service-content-squares .square-container .square-content .square-icon.paint {
    width: 43.8px;
  }
  .service-content-squares .square-container .square-content .square-heading {
    font-size: 2.8125rem;
  }
  .service-content-squares .square-container .square-content .square-sub-heading {
    font-size: 1.875rem;
  }
  .service-content-squares .square-container .square-content .square-star {
    top: -10px;
    left: -45px;
    height: 250px;
    width: 250px;
  }
  .contact-content {
    height: 1080px;
    padding-top: 50px;
  }
  .contact-content-bottom {
    margin-bottom: 73%;
  }
  .contact-content-heading h1 {
    font-size: 2.8125rem;
  }
}
@media screen and (max-width: 380px) {
  .our-focus-content-suspensions-item {
    height: 200px;
  }
  .contact-content {
    height: 1030px;
  }
  .contact-content-bottom {
    margin-bottom: 70%;
  }
}
